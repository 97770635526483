import { environment } from '../../environments/environment';

// Encryption key
export const SECRET_KEY: string = '$2a$10$e.oPc.dyrwRoQCpDvO9Rhe';

// API URLs
export const URLS = Object({
   'createPricing': `${environment.apiUrl}/pricing-template`,
   'accountActionDetails': `${environment.apiUrl}/account/actions/details`,
   'accountCreate': `${environment.apiUrl}/account/actions/create`,
   'userPayPal': `${environment.apiUrl}/user/paypal`,
   'userResellerPayment': `${environment.apiUrl}/reseller/payment`,
   'transactionList': `${environment.apiUrl}/transaction`,
   'invoiceList': `${environment.apiUrl}/invoice`,
   'priceListStatus': `${environment.apiUrl}/pricing-template/status`,
   'pricingConfig': `${environment.apiUrl}/pricing-template/config`,
   'viewPricing': `${environment.apiUrl}/pricing-template/view`,
   'updatePricing': `${environment.apiUrl}/pricing-template/update`,
   'stateapi': `${environment.apiUrl}/state`,
   'stateList': `${environment.apiUrl}/state/list`,
   'ratecenterapi': `${environment.apiUrl}/footprint/rate-center`,
   'roleapi': `${environment.apiUrl}/role`,
   'salutationapi': `${environment.apiUrl}/salutation`,
   'emailtype': `${environment.apiUrl}/emailtype`,
   'emailtypeview': `${environment.apiUrl}/emailtype/view`,
   'countryList': `${environment.apiUrl}/country/list`,
   'country': `${environment.apiUrl}/country`,
   'countryDetails': `${environment.apiUrl}/country/view`,
   'cnamapi': `${environment.apiUrl}/cnam`,
   'cnamviewapi': `${environment.apiUrl}/cnam/view`,
   'notice': `${environment.apiUrl}/notice`,
   'noticeview': `${environment.apiUrl}/notice/view`,
   'lookup': `${environment.apiUrl}/did/search`,
   'consecutiveLookup': `${environment.apiUrl}/did/search/sequence`,
   'checkout': `${environment.apiUrl}/did/reserve`,
   'checkoutInternational': `${environment.apiUrl}/did/reserve/international`,
   'registerNumber': `${environment.apiUrl}/did/reserve/grey`,
   'footprint': `${environment.apiUrl}/footprint`,
   'areaCodes': `${environment.apiUrl}/ratecenter/areacodes`,
   'footprintDetails': `${environment.apiUrl}/footprint/view`,
   'footprintInternational': `${environment.apiUrl}/footprintint`,
   'footprintInternationalDetails': `${environment.apiUrl}/footprintint/view`,
   'stateByCountry': `${environment.apiUrl}/statebycountry`,
   'portapi': `${environment.apiUrl}/port`,
   'portList': `${environment.apiUrl}/port/list`,
   'portEdit': `${environment.apiUrl}/port/submit/view`,
   'portUpdate': `${environment.apiUrl}/port/update`,
   'portUpdateForward': `${environment.apiUrl}/port/forwarding`,
   'portBuild': `${environment.apiUrl}/port/build`,
   'portviewapi': `${environment.apiUrl}/port/view`,
   'faqapi': `${environment.apiUrl}/faq`,
   'faqviewapi': `${environment.apiUrl}/faq/view`,
   'faqcategoriesapi': `${environment.apiUrl}/faq/categories`,
   'supportfaqlist': `${environment.apiUrl}/support/faq-list`,
   'supportfeedback': `${environment.apiUrl}/support/feedback`,
   'accountclassserviceapi': `${environment.apiUrl}/account/class-service`,
   'invoiceLineTypeapi': `${environment.apiUrl}/linetype`,
   'invoiceLineTypeviewapi': `${environment.apiUrl}/linetype/view`,
   'userapi': `${environment.apiUrl}/user`,
   'userstatusapi': `${environment.apiUrl}/user/status`,
   'userviewapi': `${environment.apiUrl}/user/view`,
   'role': `${environment.apiUrl}/role`,
   'roleStatus': `${environment.apiUrl}/role/status`,
   'rolepermission': `${environment.apiUrl}/rolepermission`,
   'roleDetails': `${environment.apiUrl}/role/view`,
   'roleMapping': `${environment.apiUrl}/role/mapping`,
   'assignedRole': `${environment.apiUrl}/user/role`,
   'companyapi': `${environment.apiUrl}/company`,
   'companyviewapi': `${environment.apiUrl}/company/view`,
   'companystatusapi': `${environment.apiUrl}/company/status`,
   'removeCompany': `${environment.apiUrl}/company/archive`,
   'billingtypeapi': `${environment.apiUrl}/billing-type`,
   'paymentmethodapi': `${environment.apiUrl}/payment-method`,
   'nettermapi': `${environment.apiUrl}/netterm`,
   'contacttypeapi': `${environment.apiUrl}/contact-type`,
   'accountClass': `${environment.apiUrl}/account/class`,
   'accountClassStatus': `${environment.apiUrl}/account/class-status`,
   'accountClassDetails': `${environment.apiUrl}/account/class/view`,
   'accountClassToggle': `${environment.apiUrl}/account/actions/permissions`,
   'accountClassToggleDetails': `${environment.apiUrl}/account/actions/permissions`,
   'account': `${environment.apiUrl}/account`,
   'accountDetails': `${environment.apiUrl}/account/view`,
   'accountStatus': `${environment.apiUrl}/account/status`,
   'removeAccount': `${environment.apiUrl}/account/archive`,
   'pricingTemplate': `${environment.apiUrl}/pricing-template`,
   'salutationList': `${environment.apiUrl}/salutation/list`,
   'currency': `${environment.apiUrl}/currency`,
   'login': `${environment.apiUrl}/login`,
   'logout': `${environment.apiUrl}/logout`,
   'sidebarMenu': `${environment.apiUrl}/menu`,
   'internationalCountryList': `${environment.apiUrl}/did/didww`,
   'didlistapi': `${environment.apiUrl}/did/list`,
   'didExport': `${environment.apiUrl}/did/export`,
   'vendormasterapi': `${environment.apiUrl}/vendor-master`,
   'didstatusapi': `${environment.apiUrl}/did-status`,
   'didtypeapi': `${environment.apiUrl}/did-type`,
   'didproductsapi': `${environment.apiUrl}/did-products`,
   'didcreateapi': `${environment.apiUrl}/did/create`,   
   'didupdateapi': `${environment.apiUrl}/did/update`,
   'diddeleteapi': `${environment.apiUrl}/did/delete`,
   'diddetailsapi': `${environment.apiUrl}/did/details`,
   'didcreatemusicapi': `${environment.apiUrl}/did/create-music`,
   'didcompanyownerapi': `${environment.apiUrl}/did/company-owner`,
   'didPrefix': `${environment.apiUrl}/did/exchanges`,
   'updatetagsapi': `${environment.apiUrl}/did/update-tags`,
   'viewcallwaitapi': `${environment.apiUrl}/did/view-callwait`,
   'updatecallwaitapi': `${environment.apiUrl}/did/callwaiting`,
   'createaliasesapi': `${environment.apiUrl}/did/add_alias`,
   'listaliasesapi': `${environment.apiUrl}/did/get_aliases`,
   'removealiasesapi': `${environment.apiUrl}/did/delete_alias`,
   'updateprovisionapi': `${environment.apiUrl}/device/info`,
   'diddeviceinventory': `${environment.apiUrl}/users/devices`,
   'didviewfaxapi': `${environment.apiUrl}/did/view-fax`,
   'didviewtagsapi': `${environment.apiUrl}/did/view-tags`,
   'viewemergencyapi': `${environment.apiUrl}/did/view911`,
   'viewcnamapi': `${environment.apiUrl}/did/view-cnam`,
   'viewforwardingapi': `${environment.apiUrl}/did/view-forwarding`,
   'viewprovisionapi': `${environment.apiUrl}/did/view-provision`,
   'createforwardingapi': `${environment.apiUrl}/did/create-forwarding`,
   'createcnamapi': `${environment.apiUrl}/did/create-cnam`,
   'createfaxapi': `${environment.apiUrl}/did/create-fax`,
   'createemergencyapi': `${environment.apiUrl}/did/update911`,
   'e911api': `${environment.apiUrl}/did/e911`,
   'didforwardingapi': `${environment.apiUrl}/did-forwarding`,
   'didviewdirectory': `${environment.apiUrl}/did/view411`,
   'didcreatedirectory': `${environment.apiUrl}/did/update411`,
   'didviewrtp': `${environment.apiUrl}/did/view-rtp`,
   'didcreatertp': `${environment.apiUrl}/did/updatertp`,
   'didrtpmode': `${environment.apiUrl}/did/rtpmode`,
   'didupdatesms': `${environment.apiUrl}/did/update-sms`,
   'didviewsms': `${environment.apiUrl}/did/view-sms`,
   'didMoh': `${environment.apiUrl}/did/moh`,
   'customerapi': `${environment.apiUrl}/customer`,
   'changePassword': `${environment.apiUrl}/change-password`,
   'forgotPassword': `${environment.apiUrl}/forgot-password`,
   'resetPassword': `${environment.apiUrl}/reset-password`,
   'profile': `${environment.apiUrl}/profile`,
   'register': `${environment.apiUrl}/register`,
   'vendorMaster': `${environment.apiUrl}/vendor-master`,
   'internationalBilling': `${environment.apiUrl}/company/international`,
   'internationalSettings': `${environment.apiUrl}/international-billing`,
   'userRoles': `${environment.apiUrl}/userroles`,
   'customerClass': `${environment.apiUrl}/service/customer-class`,
   'accountApprove': `${environment.apiUrl}/account/approve`,
   'accountReject': `${environment.apiUrl}/account/status`,
   'portDidTypeList': `${environment.apiUrl}/port/did-type`,
   'portCheck': `${environment.apiUrl}/port/check`,
   'portSubmit': `${environment.apiUrl}/port/checknumber`,
   'createPort': `${environment.apiUrl}/port/submit`,
   'spendLimitListing': `${environment.apiUrl}/configurations/spendlimit`,
   'spendLimitCreate': `${environment.apiUrl}/configurations/create-spendlimit`,
   'spendLimitUpdate': `${environment.apiUrl}/configurations/update-spendlimit`,
   'spendLimitDetails': `${environment.apiUrl}/configurations/spendlimit/view`,
   'DIDForwardingCreate': `${environment.apiUrl}/configurations/create-fwdconfig`,
   'DIDForwardingView': `${environment.apiUrl}/configurations/forwarding-config/view`,
   'userAccountBalance': `${environment.apiUrl}/account/getbalancewithlimit`,
   'updaterecordingapi': `${environment.apiUrl}/account/updaterecording`,
   'verifyAddress': `${environment.apiUrl}/did/verifyaddress`,
   'DIDFromNumber': `${environment.apiUrl}/did/getdidfromnum`,
   'cdrsList': `${environment.apiUrl}/xdr/list`,
   'cdrsPortUsers': `${environment.apiUrl}/xdr/portauser`,
   'cdrsExport': `${environment.apiUrl}/xdr/export2`,
   'trunkIdList': `${environment.apiUrl}/account/trunk_list`,
   'cdrsRequest': `${environment.apiUrl}/xdr/request`,
   'cdrsRequestList': `${environment.apiUrl}/xdr/request/list`, 
   'cdrsRequestDownload': `${environment.apiUrl}/xdr/request/download`,  
   // 'AudioList': `${environment.apiUrl}/call.recording`,
   'accountCompanyReseller': `${environment.apiUrl}/account/company`,
   'accountClassReseller': `${environment.apiUrl}/account/getaccountclass`,
   'accountClassResellerTemplate': `${environment.apiUrl}/account/template`,
   'billingupdate': `${environment.apiUrl}/billing/update`,
   'billingview': `${environment.apiUrl}/billing/view`,
   'didBulkStatusUpdate': `${environment.apiUrl}/did/status/update`,
   'didBulkForwardingUpdate': `${environment.apiUrl}/did/forwarding/bulkupdate`,
   'settingsSystemInfo': `${environment.apiUrl}/settings/system_info`,
   'didmoveapi': `${environment.apiUrl}/did/move`,
   'settingsList': `${environment.apiUrl}/settings/list`,
   'didtollfreeapi': `${environment.apiUrl}/did/tollfree/series`,
   'invoicing': `${environment.apiUrl}/invoice`,
   'invoicingLineType': `${environment.apiUrl}/linetype`,
   'invoicingView': `${environment.apiUrl}/invoice/view`,
   'invoicingCreate': `${environment.apiUrl}/invoice/generate`,
   'invoicingUpdate': `${environment.apiUrl}/invoice/update`,
   'deleteInvoiceLineItem': `${environment.apiUrl}/invoice/delete-lineitem`,
   'invoiceSync': `${environment.apiUrl}/invoices/sync`,
   'invoiceProcess': `${environment.apiUrl}/invoices/process`,
   'invoicePay': `${environment.apiUrl}/invoices/pay`,
   'qbSync': `${environment.apiUrl}/invoices/qbsync`,
   'invoiceSendEmail': `${environment.apiUrl}/invoice/email`,
   'invoicesExport': `${environment.apiUrl}/invoices/export`,
   'simultaneousCalls': `${environment.apiUrl}/account/actions/limitsimulcalls`,
   'simultaneousCallsView': `${environment.apiUrl}/account/actions/simulcalls/view`,
   'fraudDetection': `${environment.apiUrl}/account/actions/fraud`,
   'fraudDetectionView': `${environment.apiUrl}/account/actions/fraud/view`,
   'quickbooks': `${environment.apiUrl}/account/actions/quickbooks`,
   'quickbooksView': `${environment.apiUrl}/account/actions/quickbooks/view`,
   'shadowLogin': `${environment.apiUrl}/shadow/login`,
   'abbMaxLength': `${environment.apiUrl}/abbreviated/maxlength`,
   'abbDialingNumber': `${environment.apiUrl}/abbreviateddialingnumber`,
   'abbDialingNumberDelete': `${environment.apiUrl}/abbreviateddialingnumber/delete`,
   'discountPlan': `${environment.apiUrl}/discountplan`,
   'accountmoh': `${environment.apiUrl}/account/moh`,
   'accountInvoices': `${environment.apiUrl}/account/actions/invoices`,
   'reportsInvoices': `${environment.apiUrl}/reports/invoices`,
   'reportsStates': `${environment.apiUrl}/reports/states`,
   'reportsRatecenters': `${environment.apiUrl}/reports/ratecenters`,
   'reportsUsing': `${environment.apiUrl}/reports/using`,
   'reportsAvailable': `${environment.apiUrl}/reports/available`,
   'quickbooksSettings': `${environment.apiUrl}/quickbooks/connections`,
   'quickbooksStartHere': `${environment.apiUrl}/quickbooks/oauth2`,
   'quickbooksLineItemsList': `${environment.apiUrl}/lineitems`,
   'quickbooksLineItemView': `${environment.apiUrl}/lineitems/types`,
   'jerasoftApi': `${environment.apiUrl}/jerasoft`,
   'jerasoftlist': `${environment.apiUrl}/jerasoft/list`,
   'jerasoftview': `${environment.apiUrl}/jerasoft/view`,
   'jerasoftupdate': `${environment.apiUrl}/jerasoft/update`,
   'jerasoftcreate': `${environment.apiUrl}/jerasoft/create`,
   'jerasoftstatus': `${environment.apiUrl}/jerasoft/status`,
   'cataleyaApi': `${environment.apiUrl}/cataleya`,
   'cataleyalist': `${environment.apiUrl}/cataleya/list`,
   'cataleyaview': `${environment.apiUrl}/cataleya/view`,
   'cataleyacreate': `${environment.apiUrl}/cataleya/create`,
   'cataleyaupdate': `${environment.apiUrl}/cataleya/update`,
   'cataleyastatus': `${environment.apiUrl}/cataleya/status`,
   'vendorslocal': `${environment.apiUrl}/vendors-local`,
   'vendorsDetails': `${environment.apiUrl}/vendorlcl-details`,
   'reportsport': `${environment.apiUrl}/reports/port`,
   'reportsdisabled': `${environment.apiUrl}/reports/disabled`,
   'reportsfeature': `${environment.apiUrl}/reports/feature`,
   'accountBalance': `${environment.apiUrl}/account/getbalance`,
   'pbxActivate': `${environment.apiUrl}/users/pbx/activate`,
   'pbxDeactivate': `${environment.apiUrl}/users/pbx/deactivate`,
   'pbx': `${environment.apiUrl}/users/pbx`,
   'pbxEnable': `${environment.apiUrl}/pbx`,
   'pbxToken': `${environment.apiUrl}/pbx/token`,
   'pbxDelete': `${environment.apiUrl}/users/pbx`,
   'accountEndponits': `${environment.apiUrl}/endpoints`,
   'accountEndponitsDelete': `${environment.apiUrl}/endpoints/remove`,
   'accountEndponitsProduct': `${environment.apiUrl}/endpoint/product`,
   'accountEndponitsCreate': `${environment.apiUrl}/reseller/endpoints`,
   'accountEndponitsList': `${environment.apiUrl}/endpoints`,
   'accountEndponitsDetails': `${environment.apiUrl}/endpoints/view`,
   'accountEndponitsUpdate': `${environment.apiUrl}/endpoints`,
   'accountEndponitsWholesaleCreate': `${environment.apiUrl}/wholeseller/endpoints`,
   'accountreplenish': `${environment.apiUrl}/account/replenish`,
   'accountMoh': `${environment.apiUrl}/account/moh`,
   'accountBillingUpdate': `${environment.apiUrl}/billing/update`,
   'accountBillingView': `${environment.apiUrl}/billing/view`,
   'accountBillingStateList': `${environment.apiUrl}/billing/states`,
   'accountCallRecordingDetails': `${environment.apiUrl}/account/actions/details`,
   'subscriptionDropdownList': `${environment.apiUrl}/subscriptions/list`,
   'subscriptionCreate': `${environment.apiUrl}/subscriptions`,
   'subscriptionList': `${environment.apiUrl}/subscriptions`,
   'subscriptionDelete': `${environment.apiUrl}/subscriptions/delete`,
   'subscriptionClose': `${environment.apiUrl}/subscriptions/close`,
   'portingFileUpload': `${environment.apiUrl}/upload`,
   'portingCompanyInfo': `${environment.apiUrl}/company/details`,
   'countryRequirements': `${environment.apiUrl}/country/list`,
   'portingBulkStatus': `${environment.apiUrl}/port/status`,
   'portingVendorList': `${environment.apiUrl}/vendor-master`,
   'portingVendorUpdate': `${environment.apiUrl}/port/vendore`,
   'portingBulkDelete': `${environment.apiUrl}/port/remove`,
   'accountDialingRules': `${environment.apiUrl}/account/dialingrules`,
   'didActionsReflection': `${environment.apiUrl}/did/actions/reflection`,
   'didProducts': `${environment.apiUrl}/did/products`,
   'didForwardingProductList': `${environment.apiUrl}/did/products`,
   'pbxCustomerList': `${environment.apiUrl}/account/pbx-customers`,
   'accountClassFlags': `${environment.apiUrl}/account-class`,
   'accountClassQuickbooksList': `${environment.apiUrl}/quickbooks`,
   'didImportList': `${environment.apiUrl}/imports/background`,
   'didImportCreate': `${environment.apiUrl}/imports`,
   'exclusionList': `${environment.apiUrl}/exclusion`,
   'exclusionCreate': `${environment.apiUrl}/exclusion`,
   'exclusionDelete': `${environment.apiUrl}/exclusion`,
   'appInfo': `${environment.apiUrl}/tenant`,
   'dashboardStats': `${environment.apiUrl}/stats`,
   'paymentInfoAutoPay': `${environment.apiUrl}/paypal/autopay/agreement`,
   'paymentInfoAutoPayCancel': `${environment.apiUrl}/paypal/autopay/agreement_cancel`,
   'migrationToolCompanies': `${environment.apiUrl}/migrate/companies`,
   'migrationToolModules': `${environment.apiUrl}/migrate`,
   'createTrunkGroupapi': `${environment.apiUrl}/trunkgrp/create`,
   'updateTrunkGroupapi': `${environment.apiUrl}/trunkgrp/update`,
   'trunkGroupList': `${environment.apiUrl}/trunkgrp/list`,
   'getTrunkGroupDetail': `${environment.apiUrl}/trunkgrp/view`,
   'trunkGroupDelete': `${environment.apiUrl}/trunkgrp/delete`,
   'archiveRemoveAccount': `${environment.apiUrl}/account/delete`,
   'archiveRestoreAccount': `${environment.apiUrl}/account/restore`,  
   'archiveRemoveCompany': `${environment.apiUrl}/company/delete`,
   'archiveRestoreCompany': `${environment.apiUrl}/company/restore`,  
   'clbList': `${environment.apiUrl}/endpoints`,
   'clbUpdate': `${environment.apiUrl}/operations/clb/update`,
   'clbDetails': `${environment.apiUrl}/operations/clb/view`,
   'routingProcessApi': `${environment.apiUrl}/master/routing_types`,
   'acmeList': `${environment.apiUrl}/operations/acme/list`,
   'acmeCreate': `${environment.apiUrl}/operations/acme/create`,
   'acmeUpdate': `${environment.apiUrl}/operations/acme/update`,
   'acmeDetails': `${environment.apiUrl}/operations/acme/view`,
   'acmeDelete': `${environment.apiUrl}/operations/acme/delete`,
   'clbAcmeList': `${environment.apiUrl}/operations/clb-acme/list`,
   'clbAcmeCreate': `${environment.apiUrl}/operations/clb-acme/create`,
   'clbAcmeUpdate': `${environment.apiUrl}/operations/clb-acme/update`,
   'clbAcmeDetails': `${environment.apiUrl}/operations/clb-acme/view`,
   'clbAcmeDelete': `${environment.apiUrl}/operations/clb-acme/delete`,
   'smsGatewayList': `${environment.apiUrl}/sms-gateway`,
   'callbackAddEdit': `${environment.apiUrl}/sms-gateway/connection`,
   'callbackDelete': `${environment.apiUrl}/sms-gateway/callback`,
   'httpAddEdit': `${environment.apiUrl}/sms-gateway/connection`,
   'httpDelete': `${environment.apiUrl}/sms-gateway/http`,   
   'manageNotiList': `${environment.apiUrl}/account-events/list`,
   'manageNotiStatus': `${environment.apiUrl}/account-events/update`,
   'accountClassPaypalMerchantList': `${environment.apiUrl}/quickbooks`,
   'paypalAccountList': `${environment.apiUrl}/merchant-gateway/list`,
   'paypalAccountCreate': `${environment.apiUrl}/merchant-gateway/add`,
   'paypalAccountUpdate': `${environment.apiUrl}/merchant-gateway/update`,
   'paypalAccountDelete': `${environment.apiUrl}/merchant-gateway/delete`,
   'paymentGateway': `${environment.apiUrl}/service/payment-gateway`,
   'settingsVendorInfo': `${environment.apiUrl}/vendors-local/update`,
   'unlockDidapi': `${environment.apiUrl}/unblock_dids`,
   'vlbVendorAdd': `${environment.apiUrl}/operations/vlb-vendor/add`,
   'vlbVendorUpdate': `${environment.apiUrl}/operations/vlb-vendor/update`,
   'vlbVendorDelete': `${environment.apiUrl}/operations/vlb-vendor/delete`,
   'vlbVendorList': `${environment.apiUrl}/operations/vlb-vendor/list`,
   'vlbVendorEndpointAdd': `${environment.apiUrl}/operations/vlb-vend-endpoint-ip/add`,
   'vlbVendorEndpointUpdate': `${environment.apiUrl}/operations/vlb-vend-endpoint-ip/update`,
   'vlbVendorEndpointDelete': `${environment.apiUrl}/operations/vlb-vend-endpoint-ip/delete`,
   'vlbVendorEndpointList': `${environment.apiUrl}/operations/vlb-vend-endpoint-ip/list`,
   'vlbVendorEndpointsequence': `${environment.apiUrl}/operations/vlb-vend-endpoint-ip/sequence`,
   'didHistoryList': `${environment.apiUrl}/did-history`,
   'complaintSourcesList': `${environment.apiUrl}/masters/complaint-sources`,
   'complaintIssueList': `${environment.apiUrl}/masters/complaint-issues`,
   'createComplaint': `${environment.apiUrl}/did-complaint/add`,
   'viewComplaint': `${environment.apiUrl}/did-complaint/view`,
   'complaintList': `${environment.apiUrl}/did-complaint/list`,
   'updateComplaint': `${environment.apiUrl}/did-complaint/update`,
   'lataList': `${environment.apiUrl}/masters/did-lata`,
   'buyBulkLocalList': `${environment.apiUrl}/lcl-did-bulk-purchase/list`,
   'createBuybulklocal': `${environment.apiUrl}/lcl-did-bulk-purchase/new`,
   'orderTypeList': `${environment.apiUrl}/masters/did-events`,
   'didReport': `${environment.apiUrl}/lcl-did-bulk-purchase/did_report`,
   'summaryReport': `${environment.apiUrl}/lcl-did-bulk-purchase/summary_report`,
   'getLimit': `${environment.apiUrl}/app-constraints`,
   'migrateKazoo': `${environment.apiUrl}/pbx/kazoo-migrate`,
   'newPortCheck': `${environment.apiUrl}/port/check-ports`,
   'newSubmitPort': `${environment.apiUrl}/port/submit-request`,
   'submitPortList': `${environment.apiUrl}/port/view-submit-port`,
   'submitPortDetail': `${environment.apiUrl}/port/submit-port-details`,
   'submitPortCsv': `${environment.apiUrl}/port/submit-port-csv`,
   'newPortingFileUpload': `${environment.apiUrl}/port/upload-docs`, 
   'newPortList': `${environment.apiUrl}/port/view-list`,
   'newDidsList': `${environment.apiUrl}/port/view-did-list`,
   'portView': `${environment.apiUrl}/port/view-port-detail`,
   'updateNewPort': `${environment.apiUrl}/port/update-request`,
   'removeDocs': `${environment.apiUrl}/port/remove-upload-docs`,
   'cancelOrder': `${environment.apiUrl}/port/cancel-order`, 
   'cancelDidOrder': `${environment.apiUrl}/port/cancel-did-order`,
   'viewForwarding': `${environment.apiUrl}/port/forwarding-details`,
   'forwardingSubmitted': `${environment.apiUrl}/port/update-forwarding`,
   'deleteOrder': `${environment.apiUrl}/port/delete-request`,
   'activateMaster': `${environment.apiUrl}/port/activate-order`,
   'activatedid': `${environment.apiUrl}/port/activate-did`,
   'buildMaster': `${environment.apiUrl}/port/build-order`,
   'buildDid': `${environment.apiUrl}/port/build-did`,
   'masterExport': `${environment.apiUrl}/port/export-list`,
   'jerasoftdelete': `${environment.apiUrl}/jerasoft/delete`,
   'focReadyList': `${environment.apiUrl}/port/view-foc-list`,
   'updateFocReady': `${environment.apiUrl}/port/update-activation`,
   'viewFocReady': `${environment.apiUrl}/port/view-did-activation`,
   'didArchivelistapi': `${environment.apiUrl}/did/did-archive`,
   'problematicist': `${environment.apiUrl}/port/view-rejection-list`,
   'galaxyList': `${environment.apiUrl}/galaxy/listing`,
   'galaxyCreate': `${environment.apiUrl}/galaxy/create`,
   'galaxyStatus': `${environment.apiUrl}/galaxy/status-dropdown`,
   'galaxyDetails': `${environment.apiUrl}/galaxy/view`,
   'galaxyUpdate': `${environment.apiUrl}/galaxy/update`,
   'signallingEquipmentList': `${environment.apiUrl}/signaling/listing`,
   'signalingStatus': `${environment.apiUrl}/signaling/status-dropdown`,
   'signalingType': `${environment.apiUrl}/signaling/type-dropdown`,
   'galaxyDelete': `${environment.apiUrl}/galaxy/remove`,
   'galaxyListDrop': `${environment.apiUrl}/galaxy/listing-dropdown`,
   'galaxyStatusList': `${environment.apiUrl}/galaxy/status-listing`,
   'galaxyStatusCreate': `${environment.apiUrl}/galaxy/create-status`,
   'galaxyStatusUpdate': `${environment.apiUrl}/galaxy/update-status`,
   'galaxyStatusDetails': `${environment.apiUrl}/galaxy/view-status`,
   'galaxyStatusDelete': `${environment.apiUrl}/galaxy/remove-status`,
   'equipmentSignalingList': `${environment.apiUrl}/signaling/listing`,
   'equipmentSignalingDelete': `${environment.apiUrl}/signaling/remove`,
   'equipmentSignalingDetails': `${environment.apiUrl}/signaling/view`,
   'equipmentSignalingCreate': `${environment.apiUrl}/signaling/create`,
   'equipmentSignalingupdate': `${environment.apiUrl}/signaling/update`,
   'equipmentSignalingAddressList': `${environment.apiUrl}/signaling/address-listing`,
   'equipmentSignalingAssignAddressList': `${environment.apiUrl}/signaling/assign-address-list`,
   'AddAssignment': `${environment.apiUrl}/signaling/check-address-assign`,
   'AddressAssign': `${environment.apiUrl}/signaling/address-assign`,   
   'updateNotes': `${environment.apiUrl}/signaling/update-notes`,
   'unassignAddress': `${environment.apiUrl}/signaling/address-unassign`,
   'equipmentSignalingStatusList': `${environment.apiUrl}/signaling/status-listing`,
   'equipmentSignalingStatusDelete': `${environment.apiUrl}/signaling/remove-status`,
   'equipmentSignalingStatusDetails': `${environment.apiUrl}/signaling/view-status`,
   'equipmentSignalingStatusCreate': `${environment.apiUrl}/signaling/create-status`,
   'equipmentSignalingStatusupdate': `${environment.apiUrl}/signaling/update-status`,
   'equipmentSignalingTypeList': `${environment.apiUrl}/signaling/type-listing`,
   'equipmentSignalingTypeDelete': `${environment.apiUrl}/signaling/remove-type`,
   'equipmentSignalingTypeDetails': `${environment.apiUrl}/signaling/view-type`,
   'equipmentSignalingTypeCreate': `${environment.apiUrl}/signaling/create-type`,
   'equipmentSignalingTypeupdate': `${environment.apiUrl}/signaling/update-type`,
   'equipmentSignalingAddressDelete': `${environment.apiUrl}/signaling/remove-address`,
   'equipmentSignalingAddressDetails': `${environment.apiUrl}/signaling/view-address`,
   'equipmentSignalingAddressCreate': `${environment.apiUrl}/signaling/create-address`,
   'equipmentSignalingAddressupdate': `${environment.apiUrl}/signaling/update-address`,
   'assignedSignalingList': `${environment.apiUrl}/signaling/assign-signaling-list`, 
   'unsupportedDid': `${environment.apiUrl}/port/export-fail-did`, 
   'portoutDownload': `${environment.apiUrl}/reports/export-port-outs`, 
   'portoutSearch': `${environment.apiUrl}/reports/summary-port-outs`,
   'usfExemptionCreate': `${environment.apiUrl}/usf_exemption/create`,
   'usfExemptionView': `${environment.apiUrl}/usf_exemption/view`,
   'trunkGroupExport': `${environment.apiUrl}/trunkgrp/export`,
});