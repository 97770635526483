import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from './../_config/api.config';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable()
export class AppInfoService {
    appLogoUrl: string = null;
    appTitle:string = 'Skyetel';
    appSubPartitionUrl: string = null;
    appMainPartitionUrl = environment.mainPartitionUrl.split(",");
    appSupportEmail: string = 'support@call48.com';
    appAddress: string = '7700 Congress Avenue STE 3214, Boca Raton, FL 33487';
    appAccountId: number = null;
    loadedAppInfo: boolean = false;
    redirectToNoAccess: boolean = false;

	constructor( 
		private http: HttpClient 
	) { }
    
    // check if main partition
    isMainPartiotionDomain() {
        console.log(this.appMainPartitionUrl);
        console.log(window.location.host);        
        return this.appMainPartitionUrl.includes(window.location.host) ? true : false;
	}

	getAppInfo(filterParams?) {
		return this.http.get<any>(URLS.appInfo, {params: filterParams}).pipe(catchError(this.handleError));
	}

	handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
}