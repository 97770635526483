import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from './../../_config/api.config';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class SidebarService {

  constructor(
    private http: HttpClient
  ) { }

  getMenuList() {
    return this.http.get<any>(URLS.sidebarMenu).pipe(catchError(this.handleError));
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
    } else {
        // server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
