import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from './../../../_config/api.config';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class BuyLocalService {

	constructor(
		private http: HttpClient
	) { }

	// getDIDTypeList() {
	// 	return this.http.get<any>(URLS.portDidTypeList).pipe(retry(1), catchError(this.handleError));
	// }

	// portSubmit(data) {
	// 	return this.http.post<any>(URLS.portSubmit, data).pipe(retry(1), catchError(this.handleError));
	//   }

	//   createPort(data) {
	//       return this.http.post<any>(URLS.createPort, data).pipe(retry(1), catchError(this.handleError));
	//   }

	//   getDIDForwardingList(data?) {
	// 	return this.http.get<any>(URLS.didforwardingapi, { params: data }).pipe(retry(1), catchError(this.handleError));
	//   }

	getVendors(data) {
		return this.http.get<any>(URLS.vendorMaster, { params: data }).pipe(catchError(this.handleError));
	}

	getLimit() {
		return this.http.get<any>(URLS.getLimit).pipe(catchError(this.handleError));
	}

	getState(data?) {
		return this.http.get<any>(URLS.stateList, { params: data }).pipe(catchError(this.handleError));
	}

	getFootprint(data?) {
		return this.http.get<any>(URLS.ratecenterapi, { params: data }).pipe(catchError(this.handleError));
	}

	getLata(data?) {
		return this.http.get<any>(URLS.lataList, { params: data }).pipe(catchError(this.handleError));
	}
	
	getUnlockDids(data?) {
		return this.http.post<any>(URLS.unlockDidapi, data).pipe(catchError(this.handleError));
	}

	getAreaCode(data?) {
		return this.http.get<any>(URLS.areaCodes, { params: data }).pipe(catchError(this.handleError));
	}

	getLookUpList(data?) {
		return this.http.get<any>(URLS.lookup, { params: data }).pipe(catchError(this.handleError));
	}

	getUserType(data) {
		return this.http.get<any>(URLS.account, { params: data }).pipe(catchError(this.handleError));
	}

	checkout(data) {
		return this.http.post<any>(URLS.checkout, data).pipe(catchError(this.handleError));
	}

	getDIDForwardingList(filterParams?) {
		return this.http.get<any>(URLS.didforwardingapi, {params: filterParams}).pipe(catchError(this.handleError));
	}
	
	getProductList(filterParams?) {
		return this.http.get<any>(URLS.didProducts, {params: filterParams}).pipe(catchError(this.handleError));
	}

	getTrunkGroupList(filterParams?) {
		return this.http.get<any>(URLS.trunkGroupList, {params: filterParams}).pipe(catchError(this.handleError));
	}

	getPBXCustomerList(filterParams?): Observable<{}> {
		return this.http.get<any>(URLS.pbxCustomerList, {params: filterParams});
	}

	getAccountClassInfo(filterParams?) {
		return this.http.get<any>(URLS.accountClassFlags, {params: filterParams}).pipe(catchError(this.handleError));
	}

	// editPort(data) {
	//     return this.http.get<any>(URLS.portEdit, {params: data}).pipe(retry(1), catchError(this.handleError));
	// }

	// updatePortForward(data) {
	//     return this.http.put<any>(URLS.portUpdateForward, data).pipe(retry(1), catchError(this.handleError));
	// }

	handleError(error) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// client-side error
			errorMessage = `Error: ${error.error.message}`;
		} else {
			// server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		console.log(errorMessage);
		return throwError(errorMessage);
	}
}
// import {Injectable} from '@angular/core';
// import {HttpClient} from '@angular/common/http';
// import {Router} from '@angular/router';
// import {ToastrService} from 'ngx-toastr';
// import mock from './data';
// import {AppConfig} from '../../../app.config';

// export class Product {
//   id?: number;
//   number?: string;
//   state?: string;
//   rateCenter?: string;
//   vendor?: string;

//   constructor(id?) {
//     if (id) {
//       this.id = id;
//     }
//     this.number = '';
//     this.state = '';
//     this.rateCenter = '';
//     this.vendor = '';
//   }
// }

// @Injectable()
// export class BuyLocalService {
//   _products: Product[] = [];
//   _images: string[] = [];
//   _isReceiving: boolean = false;
//   _isUpdating: boolean = false;
//   _isDeleting: boolean = false;
//   _idToDelete: string = null;

//   config: any;

//   constructor(
//     private http: HttpClient,
//     private router: Router,
//     private toastr: ToastrService,
//     appConfig: AppConfig
//   ) {
//     this.config = appConfig.getConfig();
//   }

//   getProductsRequest() {
//     // We check if app runs with backend mode
//     if (!this.config.isBackend) {
//       this.receiveProducts(mock);
//     } else {
//       this.receivingProducts();
//       this.http.get('/products').subscribe(products => {
//         this.receiveProducts(products);
//       });
//     }
//   }

//   loadProductRequest(id) {
//     // We check if app runs with backend mode
//     if (!this.config.isBackend) {
//       this.receiveProduct(mock.find(arr => arr.id === id));
//     } else {
//       this.receivingProduct();
//       this.http.get('/products/' + id).subscribe(product => {
//         this.receiveProduct(product);
//       });
//     }
//   }

//   updateProductRequest(payload) {
//     // We check if app runs with backend mode
//     if (!this.config.isBackend) {
//       return;
//     }

//     this.updatingProduct();
//     this.http.put('/products/' + payload.product.id, payload.product).subscribe(() => {
//       this.updateProduct(payload.product);
//       this.toastr.success('DID has been Updated!');
//     });
//   }

//   createProductRequest(payload) {
//     // We check if app runs with backend mode
//     if (!this.config.isBackend) {
//       return;
//     }

//     this.updatingProduct();
//     this.http.post('/products', payload.product).subscribe(() => {
//       this.updateProduct(payload.product);
//       this.router.navigate(['/app/didsModule/viewListDids']);
//       this.toastr.success('DID has been Created!');
//     });
//   }

//   deleteProductRequest(payload) {
//     // We check if app runs with backend mode
//     if (!this.config.isBackend) {
//       return;
//     }

//     this.deletingProduct(payload);
//     this.http.delete('/products/' + payload.id).subscribe(() => {
//       this.deleteProduct({id: payload.id});
//       // if (this.router.history.current.pathname !== '/app/ecommerce/management') {
//       //   this.router.navigate(['/app/ecommerce/management']);
//       // }
//       this.toastr.success('Product has been Deleted!');
//     });
//   }

//   getProductsImagesRequest(payload) {
//     // We check if app runs with backend mode
//     if (!this.config.isBackend) {
//       return;
//     }

//     this.http.get('/products/images-list').subscribe((images: Array<string>) => {
//       this.receiveProductImages(images);
//       if (!payload.img && images.length) {
//         this.updateProduct({id: payload.id, img: images[0]});
//       }
//     });
//   }

//   receiveProducts(payload) {
//     this.products = payload;
//     this.isReceiving = false;
//   }

//   receivingProducts() {
//     this.isReceiving = true;
//   }

//   receiveProduct(payload) {
//     this.products = [...this.products, payload];
//     this.isReceiving = false;
//   }

//   receivingProduct() {
//     this.isReceiving = true;
//   }

//   updateProduct(payload) {
//     const index = this.products.findIndex((p: Product) => p.id === payload.id);
//     this.products = this.products.map((p, i) => {
//       if (i === index) {
//         return Object.assign({}, p, payload);
//       }
//       return p;
//     });
//     this.isUpdating = false;
//   }

//   updatingProduct() {
//     this.isUpdating = true;
//   }

//   deleteProduct(payload) {
//     const indexToDelete = this.products.findIndex(p => p.id === payload.id);
//     this.products.splice(indexToDelete, 1);
//     this.isDeleting = false;
//     this.idToDelete = null;
//   }

//   deletingProduct(payload) {
//     this.isDeleting = true;
//     this.idToDelete = payload.id;
//   }

//   receiveProductImages(payload) {
//     this.images = payload;
//   }

//   get products() {
//     return this._products;
//   }

//   set products(products) {
//     this._products = products;
//   }

//   get images() {
//     return this._images;
//   }

//   set images(images) {
//     this._images = images;
//   }

//   get isReceiving() {
//     return this._isReceiving;
//   }

//   set isReceiving(isReceiving) {
//     this._isReceiving = isReceiving;
//   }

//   get isUpdating() {
//     return this._isUpdating;
//   }

//   set isUpdating(isUpdating) {
//     this._isUpdating = isUpdating;
//   }

//   get isDeleting() {
//     return this._isDeleting;
//   }

//   set isDeleting(isDeleting) {
//     this._isDeleting = isDeleting;
//   }

//   get idToDelete() {
//     return this._idToDelete;
//   }

//   set idToDelete(idToDelete) {
//     this._idToDelete = idToDelete;
//   }
// }
