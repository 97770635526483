import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { AppConfig } from './app.config';
import { ToastrService } from 'ngx-toastr';
import { MenuUserRolesService } from './_helpers/menu.user.roles.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  config;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    appConfig: AppConfig,
    private toast: ToastrService,
    private userRole: MenuUserRolesService,
  ) {
    this.config = appConfig.getConfig();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = localStorage.getItem('token'); 
    let headers = {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    }

    if (typeof token !== 'undefined' && token !== null && token !== '' && token.length > 0) {
      Object.assign(headers, {Authorization: token});
    } else {
      if (this.router.url.indexOf('login') === -1 && 
          this.router.url.indexOf('register') === -1 &&
          this.router.url.indexOf('reset') === -1 &&
          this.router.url.indexOf('forgot') === -1
        ) {
          this.logoutUser();
      }
    }

    req = req.clone({
      setHeaders: headers
    });

    return next.handle(req).map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        let response = event.body;
        // on unauthorized access
        if (response.code == 401) {
          this.logoutUser();
        }
        // on access denied
        if (response.code == 403) {
          this.toast.error(response.message, 'Error');
          setTimeout(function(){
            // window.location.href = '/';
            this.router.navigateByUrl('/');
          }, 3000);
          return;
        }
      }
      return event;
    });

  }

  logoutUser() {
    /* let params = {
        redirectUrl: this.router.url,
        menuId: this.userRole.getMenuId(this.router.url)
    }; */
    localStorage.removeItem('isShadowed');
    localStorage.removeItem('shadowedUsername');
    localStorage.removeItem('shadowedUserType');
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('userType');
    localStorage.removeItem('menu');
    localStorage.removeItem('is_sub_partition_enabled');
    localStorage.removeItem('is_prepaid_customer');
    // redirect with current url
    // this.router.navigate(['/login'], {queryParams: params});
    this.router.navigate(['/login']);
  }

}