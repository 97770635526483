import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from './../../_config/api.config';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

const jwt = new JwtHelperService();

@Injectable()
export class LoginService {

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  login(credentials, userType) {
    let headers = {usertype: userType};
    return this.http.post<any>(URLS.login, credentials, {headers}).pipe(catchError(this.handleError));
  }

  saveUserInfo(username, token, userType,is_sub_partition_enabled?,is_prepaid_customer?) {
    localStorage.setItem('token', token);
    localStorage.setItem('username', username);
    localStorage.setItem('userType', userType);
    localStorage.setItem('is_sub_partition_enabled', is_sub_partition_enabled);
    localStorage.setItem('is_prepaid_customer', is_prepaid_customer);
  }

  removeUserInfo() {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('userType');
    localStorage.removeItem('menu');
    localStorage.removeItem('is_sub_partition_enabled');
    localStorage.removeItem('is_prepaid_customer');
    localStorage.removeItem('isShadowed');
    localStorage.removeItem('shadowedUsername');
    localStorage.removeItem('shadowedUserType');
    this.router.navigate(['/login']);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  isAuthenticated() {
    return !localStorage.getItem('token') ? false : true;
  }

  logout() {
    return this.http.post<any>(URLS.logout, null).pipe(catchError(this.handleError));
  }

  getPaymentGateway() {
    return this.http.get<any>(URLS.paymentGateway).pipe(catchError(this.handleError));
  }   

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
    } else {
        // server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
