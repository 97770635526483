import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from './../_config/api.config';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class MenuUserRolesService {

	constructor( 
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
	) { }
    
    // get the menu from local storage
    getMenu() {
        return JSON.parse(localStorage.getItem('menu'));
    }
    
    // find menu id from menu link
    getMenuId(menuLink: string) : number {
        // when redirected from login with redirectUrl and has menuId
        if(this.router.url.indexOf('menuId') !== -1) {
            let params = this.route.snapshot.queryParams;
            return params['menuId'];
        }

        let menu = this.getMenu();
        let menuId = null;
        menuLink = menuLink.replace(/\?.*/, '').replace('/app/', ''); // replace everything after querytsring '?' and /app/ with null to get the extact link
        
        if(menu) {
            // search in main menu first
            for (var i = 0; i < menu.length; i++) {
                if (menu[i].link && menu[i].link == menuLink) {
                    menuId = menu[i].menu_id;
                    break;
                } else {
                    if (menu[i].menu_items) {
                        // search in submenu
                        for (var j = 0; j < menu[i].menu_items.length; j++) {
                            if (menu[i].menu_items[j].link == menuLink) {
                                menuId = menu[i].menu_items[j].sub_menu_id;
                                break;
                            }
                        }
                    }
                }
            }
        }
        return menuId;
    }    

	getUserRoles(params) {
		return this.http.get<any>(URLS.userRoles, {params: params}).pipe(catchError(this.handleError));
	}

	handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
}