import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AppInfoService } from './_helpers/app.info.service';
import { BuyLocalService } from './pages/dids-module/buy-local/buy-local.service';

@Component({
	selector: 'app-root',
	template: `<router-outlet></router-outlet>`
})
export class AppComponent {

	public currentUrl: any;
	public previousUrl: any;
	public uqid: any;

	constructor(
		private title: Title,
		private app: AppInfoService,
		private router: Router,
		private buylocalService: BuyLocalService
	) {
		// check if main partition
		if(this.app.isMainPartiotionDomain()) {
			this.title.setTitle(this.app.appTitle);
			this.app.loadedAppInfo = true;
		} else {
			this.getAppInfo();
		}		

		// this.currentUrl = this.router.url;
		// router.events.subscribe(event => {
		// 	if (event instanceof NavigationEnd) {        
		// 	  this.previousUrl = this.currentUrl;
		// 	  this.currentUrl = event.url;
		// 		if(this.previousUrl === '/app/didsModule/buyLocal'){
		// 			this.uqid = localStorage.getItem('uqid');
		// 			if(this.uqid){
		// 				let didparams = {};
		// 				didparams['uqid'] = this.uqid;
		// 				this.buylocalService.getUnlockDids(didparams).subscribe((response) => {
		// 					if (response['code'] === 200) {}
		// 				})
		// 			}
		// 		}
		// 	};
		// });	
	}

	// get partition info
	getAppInfo() {
		this.app.getAppInfo().subscribe(
			response => {
				if (response.code == 200) {
					if(response.data) {
						this.app.appLogoUrl = response.data.logo_url ? response.data.logo_url : null;
						this.app.appTitle = response.data.tenant_name ? response.data.tenant_name : this.app.appTitle;
						this.app.appSubPartitionUrl = response.data.sub_partition_url ? response.data.sub_partition_url : null;
						this.app.appAccountId = response.data.tenant_id ? response.data.tenant_id : null;
						this.app.appSupportEmail = response.data.tenant_email ? response.data.tenant_email : this.app.appSupportEmail;
						this.app.appAddress = response.data.tenant_address ? response.data.tenant_address : this.app.appAddress;
						this.title.setTitle(this.app.appTitle);
					} else {
						this.app.redirectToNoAccess = true;
						this.title.setTitle(this.app.appTitle);
						this.router.navigate(['/noAccess']);
					}
				} else {
					this.app.redirectToNoAccess = true;
					this.title.setTitle(this.app.appTitle);
					this.router.navigate(['/noAccess']);
				}
				this.app.loadedAppInfo = true;
			},
			error => {
				this.app.loadedAppInfo = true;
				this.title.setTitle(this.app.appTitle);
				this.router.navigate(['/noAccess']);
			}
		);
	}
}
