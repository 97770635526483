import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {LoginService} from './pages/login/login.service';
import {Injectable} from '@angular/core';
import { MenuUserRolesService } from './_helpers/menu.user.roles.service';

@Injectable()
export class AppGuard implements CanActivate {
  constructor(
    private router: Router,
    private loginService: LoginService,
    private userRole: MenuUserRolesService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    // check if authenticated
    if (this.loginService.isAuthenticated()) {
      return true;
    } else { 
      let params = {        
        // redirectUrl: this.router.url,
        redirectUrl: state.url,
        menuId: this.userRole.getMenuId(this.router.url)
      };
      localStorage.removeItem('isShadowed');
      localStorage.removeItem('shadowedUsername');
      localStorage.removeItem('shadowedUserType');
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('userType');
      localStorage.removeItem('menu');
      localStorage.removeItem('is_sub_partition_enabled');
      localStorage.removeItem('is_prepaid_customer');
      // redirect with current url
      this.router.navigate(['/login'], {queryParams: params});
      return false;
    }
  }
}